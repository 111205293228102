@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "flowbite";

:root {
  --bg-color: #0da487;
  --bg-color1: #f8f8f8;
  --text-color: #222;
  --theme-color-rgb: 13, 164, 135;
  --theme-color1: #0e947a;
  --theme-color1-rgb: 14, 148, 122;
  --theme-color2: linear-gradient(
    90.56deg,
    var(--theme-color1) 8.46%,
    var(--theme-color) 62.97%
  );
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
}
body {
  font-size: 400;
  font-family: "Public Sans", sans-serif;
  line-height: 1.4;
  color: #222;
  position: relative;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  box-shadow: 0 0 0 30px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  box-shadow: 0 0 0 30px #fff inset;
}
